import React from 'react';

import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import SchoolForm from 'src/components/school-form/school-form';
import useSchoolValidations, { defaultValues, SchoolFormValues } from 'src/components/school-form/school-validation';
import { ADD_SCHOOL } from 'src/components/schools/school-queries';

import { Panel } from '@clubspark-react/clubspark-react-tools';

import * as styles from './add-school.module.less';

const { womanId, manId, ...addSchoolDefaultValues } = defaultValues;

const AddSchool = () => {
  const { t } = useTranslation();
  const validationSchema = useSchoolValidations();
  const [addSchool, { loading: addingSchool, error: addingSchoolError }] = useMutation(ADD_SCHOOL);

  const addingSchoolErrorMessage = addingSchoolError?.graphQLErrors[0]?.message;

  const navigateToSchools = () => navigate('/schools');

  const handleSubmit = (addSchoolInput: SchoolFormValues) => {
    addSchool({
      variables: {
        addSchoolInput,
      },
    }).then(() => navigateToSchools());
  };

  return (
    <Grid container direction="column">
      <Breadcrumbs
        paths={[
          { name: t('schools'), to: '/schools' },
          { name: t('add school'), active: true },
        ]}
      />
      <h1 className={styles.mainHeading}>{t('add school')}</h1>
      <Formik
        initialValues={addSchoolDefaultValues as SchoolFormValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <SchoolForm {...props} FieldWrapperComponent={Panel} type="add">
              <APIErrorMessage error={addingSchoolErrorMessage} />
              <Grid container direction="row">
                <Button size="md" type="button" level="tertiary" onClick={navigateToSchools}>
                  {t('back')}
                </Button>
                <Button
                  size="md"
                  type="submit"
                  spacing={{ margins: { sm: 'left' } }}
                  loading={addingSchool}
                  disabled={addingSchool}
                >
                  {t('add school')}
                </Button>
              </Grid>
            </SchoolForm>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default AddSchool;
