import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AddSchool from 'src/components/add-school/add-school';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const NewSchoolPage = () => {
  usePrivateRoute();

  return (
    <Router basepath="/schools">
      <NewSchoolRoute path="/add" />
    </Router>
  );
};

const NewSchoolRoute: React.FC<RouteComponentProps> = (props) => {
  return (
    <Layout>
      <SEO title="Add School" />
      <AddSchool {...props} />
    </Layout>
  );
};

export default NewSchoolPage;
